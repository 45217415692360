import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import styles from './ServicePanel.module.scss';
import Button from '../../../sharedComponents/button/Button';

const ServicePanel = ({
  title,
  html,
  image,
}) => (
  <div className={styles.ServicePanel}>
    <div className={styles.Image}>
      <Img fluid={image.asset.fluid} alt={title} />
    </div>
    <div className={styles.ServicesContent}>
      <h3>{title}</h3>
      <div
        className={styles.Content}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <Button
        location="/asd"
        title={`Talk to us about ${title}`}
        buttonClass="modifier"
      />
    </div>
  </div>
);

export default ServicePanel;

ServicePanel.propTypes = {
  title: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};
