import React from 'react';

import styles from './ContactForm.module.scss';

// Let's create a function which take's value as input field.
function clearMyField(myTextField) {
  console.log('myTextField = ', myTextField);
  const default_value = myTextField.defaultValue;
  const current_value = myTextField.value;

  if (current_value === '') {
    myTextField.value = default_value;
    return;
  }
  if (current_value === default_value) {
    myTextField.value = '';
    return false;
  }
}

const ContactForm = () => (
  <div className={styles.Form}>
    <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <label htmlFor="name">
        <span>Name</span>
        <input type="text" name="name" id="name" />
      </label>
      <label htmlFor="email">
        <span>Email</span>
        <input type="email" name="email" id="email" />
      </label>
      <label htmlFor="subject">
        <span>Subject</span>
        <input type="text" name="subject" id="subject" />
      </label>
      <label htmlFor="message">
        <span>Message</span>
        <textarea name="message" id="message" rows="5" />
      </label>
      <div className={styles.Controls}>
        <button type="submit">Send</button>
        <input type="reset" value="Clear" />
      </div>
    </form>
  </div>
);

export default ContactForm;
