import React from 'react';
import Img from 'gatsby-image';

import styles from './OfficeMap.module.scss';

const OfficeMap = ({ staticMap }) => (
  <div className={styles.Map}>
    <Img fluid={staticMap.childFile.childImageSharp.fluid} />
  </div>
);

export default OfficeMap;
