import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../layouts';

import Hero from '../pageComponents/homepage/hero/Hero';
import Intro from '../pageComponents/homepage/intro/Intro';
import Services from '../pageComponents/homepage/services/Services';
import Work from '../pageComponents/homepage/work/Work';
import Contact from '../sharedComponents/contact/Contact';

import styles from './index.module.scss';

const HomePage = ({
  data: {
    work: {
      edges: work,
    },
    sanityServices: {
      edges: sanityServices,
    },
    sanityWork: {
      edges: sanityWork,
    },
    staticMap,
  },
}) => {
  console.log('staticMap =', staticMap);
  return (
    <Layout
      metaTitle="Online Portfolio and CV of Dominic Ladden-Powell | jdlp.co.uk"
      metaDescription="Hi, thanks for visiting my site. Take a look around and let me know what you think."
    >
      <Hero />
      <Intro />
      <Services services={sanityServices} />
      <Work work={sanityWork} />
      <Contact staticMap={staticMap} />
    </Layout>
  );
};

export default HomePage;

export const query = graphql`
{
  sanityServices: allSanityService {
    edges {
      node {
        name
        description
        image {
          asset {
            fluid(maxHeight: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
  sanityWork: allSanityWork {
    edges {
      node {
        name
        image {
          asset {
            fluid(maxHeight: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
  work: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(/data/work)/.*\\.md$/"}}) {
    edges {
      node {
        frontmatter {
          title
          logo
          link
        }
      }
    }
  }
  guarantee: file(relativePath: { eq: "dlpdigital-minimalist-glasses.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 800, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  staticMap {
    childFile {
        childImageSharp {
            fluid(maxWidth: 400, maxHeight: 600, quality: 80) {
                ...GatsbyImageSharpFluid
            }
        }
    }
  }
}
`;

HomePage.propTypes = {
  data: PropTypes.shape({
    sanityServices: PropTypes.shape.isRequired,
    work: PropTypes.shape.isRequired,
  }).isRequired,
};
