import React from 'react';

import styles from './Contact.module.scss';
import ContactForm from './ContactForm';
import OfficeMap from './OfficeMap';

const Contact = ({ staticMap }) => (
  <div className={styles.Container}>
    <div className={styles.Content}>
      <h2>Get in touch</h2>
      <div className={styles.Form}>
        <ContactForm />
      </div>
      <div className={styles.Map}>
        <OfficeMap staticMap={staticMap} />
      </div>
    </div>
  </div>
);

export default Contact;
