import React from 'react';
import PropTypes from 'prop-types';

import styles from './Services.module.scss';
import ServicePanel from './ServicePanel';

const Services = ({ services }) => (
  <div className={styles.Container}>
    <div className={styles.Content}>
      <h2>Services</h2>
      <p>
        DLPDigital works across marketing, creative, technical
        and analytics departments to ensure a comprehensive
        approach to website growth.
      </p>
      <div className={styles.Service}>
        {
          services.map(({
            node: {
              description,
              image,
              name,
            },
          }) => (
            <ServicePanel
              title={name}
              html={description}
              image={image}
            />
          ))
          }
      </div>
    </div>
  </div>
);

Services.propTypes = {
  services: PropTypes.arrayOf({
    html: PropTypes.string.isRequired,
    frontmatter: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Services;
