import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Button from '../../../sharedComponents/button/Button';
import styles from './Hero.module.scss';

const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "dlpdigital-minimalist-glasses.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, maxHeight: 500, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Hero = () => {
  const data = useStaticQuery(query);
  return (
    <div className={styles.Container}>
      <div className={styles.Background}>
        <Img
          fluid={data.heroImage.childImageSharp.fluid}
          alt="Local Heroes heroImage certificate"
          title="Local Heroes heroImage certificate"
          className={styles.BackgroundImage}
        />
      </div>
      <div className={styles.Content}>
        <h1>DLPDigital</h1>
        <h2>Make your site sing</h2>
        <Button
          location="asd"
          title="asd"
          text="Contact"
          target="asd"
        />
      </div>
    </div>
  );
};

export default Hero;
