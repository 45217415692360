import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Button.module.scss';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const Button = ({
  location,
  title,
  text,
  target,
  variant,
  buttonClass,
}) => (
  <div className={classNames(styles.Button, styles[variant], styles[buttonClass])}>
    <AnchorLink
      to={location}
      title={title || text}
    >
      {text}
    </AnchorLink>
  </div>
);

Button.propTypes = {
  location: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  target: PropTypes.string,
  variant: PropTypes.string,
};

Button.defaultProps = {
  target: null,
  variant: null,
};

export default Button;
