import React from 'react';

import styles from './Intro.module.scss';

import Button from '../../../sharedComponents/button/Button';

const Intro = () => (
  <div className={styles.Container}>
    <div className={styles.Content}>
      <h2>Who We Are</h2>
      <p>
        DLPDigital a London-based digital consultancy helping
        businesses develop and grow their online presences.
      </p>
      <p>
        It’s no longer enough to simply “have” a website. Your
        website is front and centre for your business.
      </p>
      <p>
        Ensuring your website is easy to find and easy to use is crucial to growing your business.
      </p>
      <p>
        Get in touch today to discuss improving your website.
      </p>
      <Button
        location="/"
        title="testhome"
        text="Talk to Us"
        variant="dark"
      />
    </div>
  </div>
);

export default Intro;
