import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import styles from './Work.module.scss';

const Logo = ({ name, image }) => (
  <div className={styles.Logo}>
    <Img fluid={image.asset.fluid} alt={name} />
  </div>
);

const Work = ({ work }) => (
  <div className={styles.Container}>
    <div className={styles.Content}>
      <h2>Work</h2>
      <div className={styles.WorkLogos}>
        {
          work.map(({
            node: {
              name,
              image,
            },
          }) => (
            <Logo name={name} image={image} />
          ))
        }
      </div>
    </div>
  </div>
);

Work.propTypes = {
  work: PropTypes.arrayOf({
    node: PropTypes.shape({
      name: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
      link: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default Work;
